import React from 'react';
import ContentContainer from '../../components/common/ContentContainer';
import Layout from '../../components/Layout';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import SEO from '../../components/Seo';

interface OurStoryProps {}
const OurStory: React.FC<OurStoryProps> = props => {
  const data = useStaticQuery(graphql`
    {
      historyImage: file(relativePath: { eq: "images/history.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      about: file(relativePath: { eq: "images/resource-01.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
      about2: file(relativePath: { eq: "images/info-card-1.jpg" }) {
        childImageSharp {
          sizes(maxWidth: 600) {
            ...GatsbyImageSharpSizes
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Our Story" />
      <ContentContainer>
        <section className="">
          <div className="flex flex-col lg:flex-row">
            <div className="flex flex-col w-full lg:w-1/3">
              <Img
                className="rounded-lg w-1/2 m-auto mb-4 sm:mb-6"
                fluid={data.historyImage.childImageSharp.sizes}
              />
              <Img
                className="rounded-lg w-1/2 m-auto mb-4 sm:mb-6"
                fluid={data.about.childImageSharp.sizes}
              />
              <Img
                className="rounded-lg w-1/2 m-auto mb-4 sm:mb-6"
                fluid={data.about2.childImageSharp.sizes}
              />
            </div>
            <div className="w-full lg:w-2/3 mr-10">
              <h1 className="text-primary text-3xl font-bold">Our Story</h1>
              <p className="text-gray-700 mt-6">
                The Reading and Writing Project now partners with approximately
                700 schools, and supports tens of thousands of teachers each
                year. TCRWP runs almost forty mini-institutes annually on topics
                ranging from teaching nonfiction reading to equity, from phonics
                to supporting kids with IEPs, and it annually leads over a
                hundred locally-based institutes in school districts worldwide.
              </p>
              <p className="text-gray-700 mt-6">
                Throughout its existence, TCRWP has maintained its character as
                a close-knit team and learning organization. Perhaps nothing
                characterizes the community more than the fact that almost every
                Thursday since the organization’s inception, the entire team
                devotes a full day to studying together — poring over student
                work and data, responding to rough draft curriculum units,
                democratizing skills by teaching each other, and studying
                relevant fields of knowledge.
              </p>
              <p className="text-gray-700 mt-6">
                For the last few decades Laurie Pessah and Mary Ehrenworth,
                Senior Deputy Directors have worked closely with Calkins at the
                helm, with Pessah assuming responsibility for forging and
                maintaining relationships with schools and Ehrenworth taking the
                lead in innovative professional development methods. Calkins
                heads the team of writers and curriculum developers at TCRWP.
                Joining this team are several other senior leaders: Amanda
                Hartman leads projects with primary reading and writing, Emily
                Butler Smith has special responsibility for organizing staff
                developers’ work, and Sonja Cherry-Paul is Director of Equity
                and Diversity. In addition, a team of specialists provide
                leadership on a variety of topics — some of these people focus
                on data, some on technology, some on curriculum development.
              </p>
              <p className="text-gray-700 mt-6">
                Calkins and other Staff members at TCRWP have written many books
                over the years which are published with Heinemann and other
                places; some of these books are regarded as foundational in the
                teaching of reading, writing and phonics.
              </p>
            </div>
          </div>
        </section>
      </ContentContainer>
    </Layout>
  );
};

export default OurStory;
